body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body,
html,
*,
*::before,
*::after,
:host,
:root {
    word-wrap: break-word;
    box-sizing: border-box;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    -moz-osx-font-smoothing: grayscale;
}

input,
button,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

html {
    height: -webkit-fill-available;
}

img {
    max-width: 100%;
    max-height: 100%;
}

#root {
    height: 100%;
}

:root {
    --theme-blue: #3ac7f7;
    --theme-blue-trans: #3ac7f722;
    --theme-blue-dark: #0099cd;
    --theme-green: #15cf6f;
    --theme-green-trans: #15cf6f22;
    --theme-red: #e51010;
    --theme-red-dark: #c62828;
    --background-color: #11191f;
    --background-light: #141e26;
    --background-lighter: #18232c;
    --background-lightest: #202d38;
    --background-chat: #344451;
    --background-chat-blue: #1585aa;
    --background-transparent: #11191faa;
    --color-default: #fff;
    --color-dark: #acbac3;
    --color-darker: #73828c;
    --border-color: #1f2d38;
}

:root,
html,
body {
    background-color: var(--background-color);
    color: var(--color-default);
    color-scheme: dark;
}