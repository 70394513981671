.chat-area {
    flex: 1;
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    flex-direction: column-reverse;
    width: 100%;
    padding-block: 8px;
    position: relative;
}

.chat-flex {
    margin-bottom: auto;
}

.chat-box {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    padding: 8px 10px;
    border-top: 1px solid var(--border-color);
    background-color: var(--background-light);
}

.chat-footer {
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
}

.chat-textbox {
    display: block;
    width: calc(100% - 50px);
    line-height: 20px;
    font-size: 16px;
    min-height: 40px;
    height: 30px;
    max-height: 100px;
    background-color: var(--background-lightest);
    outline: none;
    border: none;
    border-radius: 4px;
    resize: none;
    padding: 10px 15px;
}

.chat-send:not(:disabled) {
    color: var(--theme-green);
}

.chat-date {
    width: 100%;
    justify-content: center;
    display: flex;
    margin-bottom: 10px;
    gap: 10px;
    flex-direction: column;
}

.chat-flex .chat-date:first-child {
    flex: 1;
}

.chat-date-block {
    margin-bottom: auto;
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    padding-block: 0 10px;
    margin: 0 auto;
    position: sticky;
    top: 0;
    z-index: 1;
}

.chat-date-item {
    padding: 5px 20px;
    font-size: 12px;
    font-weight: 500;
    color: var(--color-darker);
    background-color: var(--background-lighter);
    border-radius: 20px;
    border: 1px solid var(--border-color);
}








.chatbox {
    display: flex;
    width: 100%;
    padding: 2px 0;
    flex-wrap: nowrap;
    gap: 5px;
    align-items: flex-end;
}

.chatbox.chatbox-friend {
    flex-direction: row;
}

.chatbox.chatbox-current {
    flex-direction: row-reverse;
}

.chatbox-area {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    padding: 5px;
    max-width: calc(100% - 80px);
    min-width: 100px;
    padding: 10px;
    border-radius: 8px;
    position: relative;
    gap: 10px;
}

.chatbox.chatbox-friend+.chatbox-friend .chatbox-area {
    border-top-left-radius: 0;
}

.chatbox.chatbox-friend:has(+.chatbox-friend) .chatbox-area {
    border-bottom-left-radius: 0;
}

.chatbox.chatbox-current:has(+ .chatbox-current) .photo,
.chatbox.chatbox-friend:has(+ .chatbox-friend) .photo {
    visibility: hidden;
}

.chatbox.chatbox-friend .chatbox-area {
    background-color: #1b2b41;
    color: #fffe;
}

.chatbox.chatbox-current+.chatbox.chatbox-current .chatbox-area {
    border-top-right-radius: 0;
}

.chatbox.chatbox-current:has(+.chatbox-current) .chatbox-area {
    border-bottom-right-radius: 0;
}

.chatbox.chatbox-current .chatbox-area {
    background-color: var(--background-chat);
}

.chatbox-message {
    text-align: left;
    white-space: pre-wrap;
    max-width: 100%;
}

.chatbox-time {
    font-size: 11px;
    font-weight: 500;
    margin-left: auto;
}
.chat-date-skeleton {
    width: 70px;
    height: 20px;
    line-height: 20px;
    font-size: 20px;
    border-radius: 10px;
    overflow: hidden;
}

.chat-skeleton {
    line-height: 3;
    width: 40%;
}

.chatbox-current .chat-skeleton {
    text-align: right;
}

.chat-bucket {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    align-items: flex-end;
    gap: 10px;
    padding: 0 10px;
    transition: .2s;
}

.chat-bucket-image {
    width: 30px;
    min-width: 30px;
    height: 30px;
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    bottom: 0;
}

.chat-bucket-block {
    flex: 1;
    width: calc(100% - 40px);
}

.chat-bucket-reverse {
    flex-direction: row-reverse;
}
.chat-bucket-pending {
    position: absolute;
    inset: -3px;
}

.chat-error {
    padding: 2px 10px 2px 50px;
    font-size: 13px;
    font-weight: 500;
    color: var(--theme-red);
}


.chat-link {
    text-decoration: none;
    color: var(--theme-green);
    transition: .2s;
}

.chat-link:hover {
    color: var(--theme-blue);
}