.history-icon {
    width: 40px;
}

.history-header-flex {
    display: inline-flex;
    flex-wrap: nowrap;
    gap: 10px;
}


.history-chat {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    border-bottom: 1px solid var(--border-color);
}

.history-chat-detail {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 10px 10px 10px 65px;
    margin-left: -60px;
    gap: 5px;
    transition: .2s;
}

.history-chat-image {
    width: 60px;
    height: 100%;
    z-index: 1;
    transition: .2s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.history-chat-active>div:hover {
    background-color: var(--background-lighter);
    cursor: pointer;
}

.history-chat-dataline {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    font-weight: 500;
}
.history-chat-name {
    color: var(--color-default);
    font-size: 16px;
    flex: 1;
}
.history-chat-time {
    font-size: 10px;
    color: var(--color-darker);
    min-width: 30px;
}
.history-chat-message {
    display: block;
    width: 100%;
    font-size: 13px;
    color: var(--color-dark);
}

.history-get-started {
    display: flex;
    padding: 10px;
    margin-top: 30px;
    justify-content: center;
    align-items: center;
}
.history-get-started-search {
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    background: var(--background-lighter);
    border: none;
    border-radius: 5px;
    font-weight: 500;
    color: var(--theme-green);
    transition: .2s;
}
.history-get-started-search:hover {
    background-color: var(--background-lightest);
    cursor: pointer;
}